<template>
  <b-button variant="outline-secondary" class="ml-2" target="_top" @click="downloadReport">
    <i class="fa-solid fa-download mr-2"></i>Purchased Tickets Report</b-button
  >
</template>

<script>
import ReportService from '@/lib/report-service-v2';
import { downloadFormattedCSV } from '@/lib/download-file';

export default {
  props: {
    eventId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      organizationId: null,
      csvData: null,
      buttonLoading: false
    };
  },
  methods: {
    async downloadReport() {
      this.buttonLoading = true;

      try {
        this.csvData = await ReportService.retrieveTicketsReport({
          eventId: this.eventId,
          contentType: 'text/csv',
          reportName: 'purchased-tickets-report'
        });

        downloadFormattedCSV('purchased-tickets-report.csv', this.csvData);
      } catch (error) {
        this.errorMessage = this.parseError(error).message;
      } finally {
        this.buttonLoading = false;
      }
    }
  }
};
</script>
