<template>
  <b-container class="mb-7">
    <b-alert v-if="errorMessage" variant="danger" show>{{ errorMessage }}</b-alert>
    <b-spinner v-if="loadingEvent">Loading Raffle...</b-spinner>
    <div v-else>
      <h1>{{ eventName }}</h1>
      <b-card-group class="items-start" deck>
        <b-card :img-src="logoUrl" class="mb-8 raffle-sidebar">
          <b-card-body class="p-0">
            <div class="flex flex-wrap mb-4 control-panel">
              <div v-if="showNewPrizeFlow" class="w-full mb-4 close-raffle">
                <CloseRaffleButton :event="event" v-if="event.drawType !== 'barrel'" />
              </div>

              <ActivateRaffleModal
                v-if="canActivateRaffle && settings.canActivateEvents && eventStatus === 'pending'"
                modalId="modal"
                :event="event"
                class="mr-2 mb-2"
              />

              <ActivateRaffleRequestModal
                v-else-if="canRequestRaffleActivation && eventStatus === 'pending'"
                modalId="modal"
                :event="event"
                class="mr-2 mb-2"
              />
              <EditRaffleModalV2
                v-if="showEditRaffle"
                modalId="modal"
                :event="event"
                class="mr-2 mb-2"
                @updateEventTags="updateEventTags"
              />

              <DeactivateRaffleModal
                v-if="canDeactivateRaffle && settings.canActivateEvents && eventStatus === 'active'"
                modalId="modal"
                :event="event"
                class="mr-2 mb-2"
              />

              <DeleteRaffleModal
                v-if="canDeleteRaffle && eventStatus !== 'active'"
                modalId="modal"
                :event="event"
                class="mr-2 mb-2"
              />

              <CreateCashSaleModal
                :compact="true"
                :event="event"
                v-if="canCreateCashSales"
                :disabled="eventStatus === 'closed' || eventStatus === 'pending' || eventStatus === 'ended'"
                class="mr-2 mb-2"
              />

              <EndRaffleModal
                v-if="canEndRaffle && eventStatus === 'active'"
                modalId="modal"
                :event="event"
                class="mr-2 mb-2"
              />
            </div>

            <p class="flex items-center justify-between mb-2">
              <b-link :href="raffleboxUrl + '/raffle/' + shortlink" class="text-green-800" target="top">
                Preview<i class="fa-solid fa-sm fa-up-right-from-square pl-2"></i>
              </b-link>
              <EventStatusBadge :status="eventStatus" />
            </p>
            <p v-if="showInboundCheckout">
              <b-link :href="getInboundCheckoutUrl()" class="text-green-800" target="top">
                Inbound Checkout<i class="fa-solid fa-sm fa-up-right-from-square pl-2"></i>
              </b-link>
            </p>

            <div class="text-right">
              <button
                class="text-sm text-gray-600"
                :class="openDrawer ? null : 'collapsed'"
                :aria-expanded="openDrawer ? 'true' : 'false'"
                aria-controls="sidebar-information"
                @click="openDrawer = !openDrawer"
              >
                <span class="underline">{{ openDrawer ? 'Less' : 'More' }} details</span>
              </button>
            </div>

            <b-collapse visible id="sidebar-information" v-model="openDrawer" class="sidebar-information">
              <hr />

              <div class="mb-2" v-if="description">
                <p class="mb-2 d-inline"><strong>Description</strong></p>
                <b-link v-b-modal="'description-modal'" class="d-block">View Description</b-link>
                <b-modal id="description-modal" size="lg" title="Event Description" centered>
                  <div class="description" v-html="description"></div>
                </b-modal>
              </div>
              <p class="mb-3" v-if="event && event.parentEvent">
                <strong>Parent Raffle</strong>
                <span
                  style="margin-left: 0.5rem"
                  v-b-tooltip.hover.top="
                    'This Raffle is a bonus draw. Ticket Buyers will be able to purchase for the associated Raffles in the Checkout page.'
                  "
                >
                  <i class="fa-solid fa-circle-info"></i>
                </span>
                <span class="d-block">
                  <a :href="`/raffle?id=${event.parentEvent.id}`">{{ event.parentEvent.shortlink }}</a>
                </span>
              </p>
              <p class="mb-3" v-if="event.childEvents && event.childEvents.length > 0">
                <strong>Bonus Draw Raffles</strong>
                <span
                  style="margin-left: 0.5rem"
                  v-b-tooltip.hover.top="
                    'This Raffle contains additional bonus draw Raffles. Ticket Buyers will be able to purchase for multiple Raffles in the Checkout page.'
                  "
                >
                  <i class="fa-solid fa-circle-info"></i>
                </span>
                <span v-for="childEvent in event.childEvents" :key="childEvent.id" class="d-block">
                  <a :href="`/raffle?id=${childEvent.id}`">{{ childEvent.shortlink }}</a>
                </span>
              </p>
              <p class="mb-3">
                <strong>Province</strong>
                <span class="d-block">{{ province }}</span>
              </p>
              <p class="mb-3" v-if="startDate">
                <strong>Ticket Sales Start</strong>
                <span class="d-block" v-if="startDate">
                  {{ formatDateTime(startDate, { timeZone: event.timeZone, displayTimeZone: true }) }}
                </span>
              </p>
              <p class="mb-3" v-if="totalRaisedGoal">
                <strong>Total Raised Goal</strong>
                <span class="d-block">{{ formatCurrency(totalRaisedGoal) }}</span>
              </p>
              <p class="mb-3">
                <strong>Ticket Sales End</strong>
                <span class="d-block" v-if="endDate">
                  {{ formatDateTime(endDate, { timeZone: event.timeZone, displayTimeZone: true }) }}
                </span>
                <span v-else class="d-block"><Badge variant="warning" label="Missing" /></span>
              </p>
              <p class="mb-3">
                <strong>Draw Date</strong>
                <span class="d-block" v-if="drawDate">{{
                  formatDateTime(drawDate, { timeZone: event.timeZone, displayTimeZone: true })
                }}</span>
                <span v-else class="d-block"><Badge variant="warning" label="Missing" /></span>
              </p>
              <p class="mb-3" v-if="event.frequency">
                <strong>Frequency</strong>
                <span class="d-block">{{ event.frequency }}</span>
              </p>
              <p class="mb-3">
                <strong>Draw Location</strong>
                <span class="d-block" v-if="drawLocation">{{ drawLocation }}</span>
                <span v-else class="d-block"><Badge variant="warning" label="Missing" /></span>
              </p>
              <p class="mb-3" v-if="maxJackpot">
                <strong>Max Total Sales</strong>
                <span class="d-block">{{ formatCurrency(maxJackpot) }}</span>
                <Badge v-if="isEventOversold" variant="danger" label="Max Jackpot Exceeded" />
              </p>
              <div class="mb-3">
                <p class="mb-1 d-block"><strong>Ticket Packages</strong></p>
                <span class="d-block" v-if="!ticketPackages || ticketPackages.length === 0"
                  ><Badge variant="warning" label="Missing"
                /></span>
                <div v-for="ticketPackage of ticketPackages" :key="ticketPackage.uuid">
                  <p class="mb-0 ml-4">
                    {{ ticketPackage.series }} - {{ ticketPackage.numTickets }} for
                    {{ formatCurrency(ticketPackage.price) }}
                    <span v-if="ticketPackage.limit">(Limit {{ ticketPackage.limit }})</span>
                    <span v-if="isPackageOversold(ticketPackage)"><Badge variant="danger" label="Oversold" /></span>
                  </p>
                </div>
              </div>
              <p class="mb-3" v-if="startingTicketNumber">
                <strong>Starting Ticket #</strong><span class="d-block">{{ startingTicketNumber }}</span>
              </p>
              <p class="mb-3" v-if="startingPot > 0">
                <strong>Starting Pot</strong><span class="d-block">${{ startingPot }}</span>
              </p>
              <p class="mb-3" v-if="licenseNumber">
                <strong>License #</strong>
                <b-link v-if="licenseUrl" :href="licenseUrl" target="_blank" rel="noopener" download>
                  {{ licenseNumber }}
                  <font-awesome-icon :icon="['far', 'arrow-down-to-line']" />
                </b-link>
                <span v-else> {{ licenseNumber }} </span>
              </p>
              <p class="mb-3">
                <strong>Draw Type</strong><span v-if="drawType" class="d-block">{{ drawType }}</span>
                <span v-else class="d-block"><Badge variant="warning" label="Missing" /></span>
              </p>
              <p class="mb-3">
                <strong>Show on Live Raffles page</strong>
                <span class="d-block">{{ formatBoolean(showLiveRaffles) }}</span>
              </p>
              <div class="mb-0" v-if="rules">
                <strong>Rules</strong>
                <b-link class="d-block" v-b-modal="'rules-modal'" v-if="rules">View Rules</b-link>
                <b-modal id="rules-modal" size="lg" title="Rules" centered>
                  <div v-html="rules"></div>
                </b-modal>
              </div>
              <p class="mb-3 mt-3" v-if="pixelCode">
                <strong>Pixel Code</strong><span class="d-block">{{ pixelCode }}</span>
              </p>
              <p class="mb-3 mt-3">
                <strong>Postal Code Check</strong><span class="d-block">{{ formatBoolean(postalCodeCheck) }}</span>
              </p>
              <div v-if="showRaffleTags && tags.length > 0">
                <p class="mb-1 mt-3">
                  <strong>Tags</strong>
                </p>
                <div>
                  <Badge v-for="tag in tags" :key="tag" variant="success" :label="tag" class="mr-1 mb-1" />
                </div>
              </div>

              <div v-if="shortlink">
                <hr />
                <p class="mb-1 mt-3">
                  <strong>QR Code</strong>
                </p>
                <div>
                  <DownloadFile :retrieveData="retrieveData" :filename="`${shortlink}-QR-code.png`">
                    <template #default="{ downloadFile }">
                      <div class="inline-flex group cursor-pointer" @click="downloadFile">
                        <QrCodeVue
                          :value="`${raffleboxUrl}/raffle/${shortlink}`"
                          ref="raffleQRCodeComponent"
                          render-as="canvas"
                          size="150"
                          class="inline-flex"
                        />
                        <div
                          class="
                            hidden
                            qr-download-wrapper
                            bg-gray-800
                            border-2 border-gray-900
                            opacity-70
                            shadow-md
                            absolute
                            left-0
                            top-0
                            justify-center
                            items-center
                            group-hover:flex
                          "
                        ></div>
                        <div
                          class="
                            hidden
                            qr-download-wrapper
                            absolute
                            left-0
                            top-0
                            justify-center
                            items-center
                            group-hover:flex
                          "
                        >
                          <font-awesome-icon
                            class="text-5xl shadow-lg text-white"
                            :icon="['far', 'arrow-down-to-line']"
                          />
                        </div>
                      </div>
                    </template>
                  </DownloadFile>
                </div>
              </div>
            </b-collapse>
          </b-card-body>
        </b-card>

        <div class="tab-container">
          <b-tabs content-class="pt-8 pb-4 tab-border" justified>
            <b-tab title="Sales Summary" v-if="eventId">
              <EventRaffleSummary
                v-if="showSalesSummaryV2"
                :eventId="eventId"
                :startDate="event.startDate"
                :endDate="event.endDate"
                :jackpotPercent="event.settings.fractionalJackpotPercent"
              />

              <EventSalesReport v-else :eventId="eventId" :jackpotPercent="event.settings.fractionalJackpotPercent" />
            </b-tab>

            <b-tab title="Device Sales" v-if="eventId && event.settings.allowInVenueSales">
              <CashDrop
                v-if="showCashDrops"
                :eventId="eventId"
                :eventClosed="!!event.closedDate"
                :organizationId="this.event.organizationId"
              />
              <SalesBySellerReport v-if="showSalesBySeller" :eventId="eventId" />
              <DeviceSalesReport :eventId="eventId" class="mb-5" />
              <TopSellersReport v-if="showTopSellersReport" :eventId="eventId" class="mt-7" />
            </b-tab>
            <b-tab title="Orders" lazy>
              <div class="mb-8 flex flex-row">
                <b-button variant="outline-secondary" :href="'/orders/?eventId=' + eventId"> View Orders </b-button>
                <ImportOrdersModal :eventId="eventId" class="ml-3" v-if="showImportOrders" />
                <b-button
                  variant="outline-secondary"
                  v-b-modal.send-test-order-email-modal
                  v-if="canSendTestOrderEmail"
                >
                  Send Test Email
                </b-button>
                <SendTestOrderEmailModal :eventId="eventId" modalId="modal" />
              </div>
              <div class="metabase-container">
                <h2 class="pb-2 border-b-2 card-title">Successful Orders</h2>
                <QuestionReport :eventId="eventId" :questionNumber="62" />
                <h2 class="pb-2 border-b-2 card-title">Failed Orders</h2>
                <QuestionReport :eventId="eventId" :questionNumber="63" />
                <h2 v-if="!decommissionMetabase" class="pb-2 border-b-2 card-title">Voided Orders</h2>
                <QuestionReport v-if="!decommissionMetabase" :eventId="eventId" :questionNumber="36" />
                <VoidedOrdersReport v-else :eventId="eventId" />
              </div>
            </b-tab>
            <b-tab title="Tickets" @click="loadTab('Tickets')">
              <div v-if="activeTab === 'Tickets'">
                <div class="mb-2">
                  <b-button class="mb-2" variant="outline-secondary" :href="'/tickets/?eventId=' + eventId">
                    <i class="fa-solid fa-ticket mr-1"></i> View Tickets
                  </b-button>
                  <b-button
                    variant="outline-secondary"
                    class="ml-2 mb-2"
                    target="_top"
                    :href="'/tickets/print-mail?eventId=' + eventId"
                    v-if="canPrintOrder"
                  >
                    <i class="fa-solid fa-envelope mr-2"></i>Print Tickets for Mailing</b-button
                  >
                  <PurchasedTicketsReport class="mb-2" :eventId="eventId" v-if="decommissionMetabase" />
                </div>
                <TicketSalesReportV2 v-if="showTicketPackageSalesReportV2" :eventId="eventId" class="my-10" />
                <TicketSalesReport v-else :eventId="eventId" class="my-10" />
                <div v-if="!decommissionMetabase">
                  <b-card title="Purchased Tickets" border-variant="light">
                    <QuestionReport :eventId="eventId" :questionNumber="97" />
                  </b-card>
                </div>
                <b-card title="Voided Tickets" border-variant="light">
                  <QuestionReport :eventId="eventId" :questionNumber="54" />
                </b-card>
              </div>
            </b-tab>
            <b-tab title="In Support Of" lazy>
              <EventMembers v-if="showEventMembersV2" :event="event" />
              <div v-else>
                <EventMemberList :event="event" @error="onError" />
                <InSupportSalesReport :eventId="eventId" class="mt-11" />
              </div>
            </b-tab>
            <b-tab title="Prizes & Winner" v-if="showNewPrizeFlow && !isGoldrush" lazy>
              <PrizeWinTab />
            </b-tab>
            <b-tab
              title="Random Number Generator"
              v-if="this.event.organizationId === 'b206c263-1437-43d0-b452-583f42542f04'"
              lazy
            >
              <h3>Random Number Generator</h3>
              <p>Generates a random number.</p>

              <Alert variant="yellow" icon="exclamation">
                Note: This is not meant to be replace the Prizes feature.
              </Alert>
              <RandomNumberGenerator />
            </b-tab>
            <b-tab title="Email Details">
              <div class="mt-3 mr-5">
                <p class="mt-2">
                  <strong>Display Ticket Numbers to Customers</strong
                  ><span class="d-block">{{ formatBoolean(showTicketNumbers) }}</span>
                </p>
                <p v-if="!emailTicketBody" class="mt-2"><strong>Email Ticket Body: </strong> None</p>
                <p v-if="!emailTicketFooter" class="mt-2"><strong>Email Ticket Footer: </strong> None</p>
              </div>
              <div v-if="emailTicketBody" class="mt-4 mr-5">
                <h5 class="mt-2">Email Ticket Body:</h5>
                <div class="col-10 p-3 border rounded" v-html="emailTicketBody"></div>
              </div>
              <div v-if="emailTicketFooter" class="mt-4 mr-5">
                <h5 class="mt-2">Email Ticket Footer:</h5>
                <div class="p-3 border rounded" v-html="emailTicketFooter"></div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </b-card-group>
    </div>
  </b-container>
</template>

<script>
import { getAuth, Roles } from '@rafflebox-technologies-inc/auth-service-sdk';
import { isEventMaxJackpotOversold, isTicketPackageOversold } from '@rafflebox-technologies-inc/rafflebox-lib';
import EventServiceV2 from '@/lib/event-service-v2';
import config from '@/config';
import OrganizationServiceV2 from '@/lib/organization-service-v2';
import EditRaffleModalV2 from '@/components/modals/EditRaffleModalV2';
import ActivateRaffleModal from '@/components/modals/ActivateRaffleModal';
import DeactivateRaffleModal from '@/components/modals/DeactivateRaffleModal';
import ActivateRaffleRequestModal from '@/components/modals/ActivateRaffleRequestModal';
import CreateCashSaleModal from '@/components/modals/CreateCashSaleModal';
import DeleteRaffleModal from '@/components/modals/DeleteRaffleModal';
import EndRaffleModal from '@/components/modals/EndRaffleModal';
import Alert from '@/components/ui/Alert.vue';

import ImportOrdersModal from '@/components/modals/ImportOrdersModal';
import SendTestOrderEmailModal from '@/components/SendTestOrderEmailModal';
import CloseRaffleButton from '@/components/CloseRaffleButton';
import QuestionReport from '@/components/reports/QuestionReport';
import DeviceSalesReport from '@/components/reports/DeviceSalesReport';
import CashDrop from '@/components/CashDrop';
import TopSellersReport from '@/components/reports/TopSellersReport';
import TicketSalesReport from '@/components/reports/TicketSalesReport';
import EventSalesReport from '@/components/reports/EventSalesReport';
import InSupportSalesReport from '@/components/reports/InSupportSalesReport.vue';
import EventMemberList from '@/components/EventMemberList';
import PrizeWinTab from '@/components/prizes/PrizeWinTab';
import EventStatusBadge from '@/components/EventStatusBadge';
import RandomNumberGenerator from '@/components/RandomNumberGenerator';
import SalesBySellerReport from '@/components/reports/SalesBySellerReport';
import { unleashFeatureEnabled, UnleashKeys } from '@/lib/unleash';
import QrCodeVue from 'qrcode.vue';
import DownloadFile from '@/components/DownloadFile';
import EventMembers from '@/components/EventMembers';
import EventRaffleSummary from '@/components/EventRaffleSummary';
import TicketSalesReportV2 from '@/components/reports/TicketSalesReportV2.vue';
import Badge from '@/components/rbComponents/Badge.vue';
import VoidedOrdersReport from '@/components/reports/VoidedOrdersReport.vue';
import PurchasedTicketsReport from '@/components/reports/PurchasedTicketsReport.vue';

export default {
  components: {
    Alert,
    CloseRaffleButton,
    RandomNumberGenerator,
    EventMemberList,
    EventStatusBadge,
    EditRaffleModalV2,
    ActivateRaffleModal,
    DeactivateRaffleModal,
    ActivateRaffleRequestModal,
    CreateCashSaleModal,
    QuestionReport,
    EventSalesReport,
    DeviceSalesReport,
    CashDrop,
    TopSellersReport,
    TicketSalesReport,
    InSupportSalesReport,
    DeleteRaffleModal,
    SendTestOrderEmailModal,
    ImportOrdersModal,
    EndRaffleModal,
    PrizeWinTab,
    SalesBySellerReport,
    QrCodeVue,
    DownloadFile,
    EventMembers,
    EventRaffleSummary,
    TicketSalesReportV2,
    VoidedOrdersReport,
    Badge,
    PurchasedTicketsReport
  },
  data() {
    return {
      activeTab: 'Sales',
      raffleboxUrl: config.RAFFLEBOX_URL,
      checkoutUrl: config.CHECKOUT_URL,
      voidedOrdersUrl: null,
      errorMessage: null,
      eventId: null,
      province: null,
      timeZone: null,
      eventName: null,
      eventStatus: null,
      showTicketNumbers: false,
      emailTicketBody: null,
      emailTicketFooter: null,
      loadingEvent: true,
      event: null,
      manualWinningTicket: '',
      pickingWinner: false,
      totalRaisedGoal: null,
      description: null,
      startDate: null,
      endDate: null,
      shortlink: null,
      logoUrl: null,
      startingTicketNum: null,
      maxNumTickets: null,
      licenseNumber: null,
      licenseUrl: null,
      pixelCode: null,
      postalCodeCheck: false,
      showLiveRaffles: false,
      rules: null,
      pickWinnerError: null,
      drawType: null,
      maxJackpot: null,
      ticketPackages: null,
      startingPot: null,
      noV2Winners: false,
      settings: {
        canActivateEvents: false
      },
      success: {
        text: '',
        model: false
      },
      isRbAdmin: false,
      openDrawer: true,
      windowWidth: window.innerWidth,
      showSalesTab: true,
      showTopSellersReport: false,
      showEditRaffle: false,
      canCreateCashSales: false,
      tags: [],
      showRaffleTags: false,
      canDeactivateRaffle: false,
      canActivateRaffle: false,
      canDeleteRaffle: false,
      canEndRaffle: false,
      canPrintOrder: false,
      canSendTestOrderEmail: false,
      canRequestRaffleActivation: false,
      showNewPrizeFlow: false,
      showEventMembersV2: false,
      showInboundCheckout: false,
      showSalesSummaryV2: false,
      showTicketPackageSalesReportV2: false,
      decommissionMetabase: false
    };
  },
  computed: {
    rngEnabled: function () {
      return this.drawType === 'Random Number Generator';
    },
    isEventOversold() {
      return isEventMaxJackpotOversold(this.event);
    },
    isGoldrush: function () {
      return !!this.event.goldrush;
    },
    showInSupportTable: function () {
      return config.ENV === 'test';
    }
  },
  methods: {
    loadTab(name) {
      this.activeTab = name;
    },
    isPackageOversold(tp) {
      return isTicketPackageOversold(tp);
    },
    onError(error) {
      if (error.response) {
        this.errorMessage = error.response.data.errors[0].message;
        if (error.response.data.errors[0].error === 'ResourceNotFoundError') {
          // If a user changes orgs whilst on a raffle of a different org display message and redirect
          setTimeout(() => {
            this.$router.push('/raffles');
          }, 3000);
        }
      } else {
        this.errorMessage = error;
      }
    },
    handleSuccess(text) {
      this.success.text = text;
      this.success.model = true;
      this.$root.$emit('bv::hide::modal', 'pick-winner-modal');
      this.success.showWinner = true;
      location.reload();
    },
    async updateEventTags() {
      this.event = await EventServiceV2.retrieveEvent(this.eventId);
    },
    async retrieveData() {
      return await new Promise((resolve) => {
        this.$refs.raffleQRCodeComponent.$el.querySelector('canvas').toBlob(resolve);
      });
    },
    getInboundCheckoutUrl() {
      if (this.event.parentEventId) {
        return `${this.checkoutUrl}/single?eventId=${this.event.parentEventId}&locale=en&createOrder=true`;
      } else {
        return `${this.checkoutUrl}/single?eventId=${this.event.id}&locale=en&createOrder=true`;
      }
    }
  },
  async mounted() {
    try {
      this.loadingEvent = true;
      this.showRaffleTags = await unleashFeatureEnabled(UnleashKeys.EditRaffleTags);
      this.showEventMembersV2 = await unleashFeatureEnabled(UnleashKeys.EventMemberV2);
      this.showInboundCheckout = await unleashFeatureEnabled(UnleashKeys.InboundCheckout);
      this.showSalesSummaryV2 = await unleashFeatureEnabled(UnleashKeys.SalesSummaryV2);
      this.showTicketPackageSalesReportV2 = await unleashFeatureEnabled(UnleashKeys.Mongo);
      this.decommissionMetabase = await unleashFeatureEnabled(UnleashKeys.DecommissionMetabase);

      const eventId = this.$route.query.id;
      if (!eventId) {
        throw new Error('Missing id');
      }

      // Event v2
      this.event = await EventServiceV2.retrieveEvent(eventId);
      await this.$store.dispatch('setEvent', this.event);
      await this.$store.dispatch('listPrizes', this.event.id);
      await this.$store.dispatch('displayCloseButton');
      this.$store.dispatch('resetPrizes');

      this.eventId = eventId;
      this.province = this.event.province;
      this.timeZone = this.event.timeZone;
      this.eventName = this.event.name;
      this.eventStatus = this.event.status;
      this.showTicketNumber = this;
      this.emailTicketFooter = this.event.emailTicketFooter;
      this.emailTicketBody = this.event.emailTicketBody;
      this.postalCodeCheck = !this.event.disablePostalCheckV2;
      this.description = this.event.description;
      this.startDate = this.event.startDate ? this.parseISO(this.event.startDate) : null;
      this.endDate = this.parseISO(this.event.endDate);
      this.drawDate = this.event.drawDate ? this.parseISO(this.event.drawDate) : null;
      this.drawLocation = this.event.drawLocation;
      this.shortlink = this.event.shortlink;
      this.logoUrl = this.event.logoUrl ? this.event.logoUrl : require.context('@/assets', true)('./no-image.png');
      this.startingTicketNumber = this.event.startingTicketNumber;
      this.licenseNumber = this.event.licenseNumber;
      this.licenseUrl = this.event.licenseUrl;
      this.pixelCode = this.event.pixelCode;
      this.showLiveRaffles = this.event.show;
      this.rules = this.event.rules;
      this.maxJackpot = this.event.maxJackpot ? Number(this.event.maxJackpot) : null;
      this.ticketPackages = this.event.ticketPackages;
      this.startingPot = Number(this.event.startingPotCents) / 100;
      this.showCashDrops = (await unleashFeatureEnabled(UnleashKeys.CashDrop)) && !this.event.settings.removeCashButton;
      this.showSalesBySeller = await unleashFeatureEnabled(UnleashKeys.SalesBySeller);
      this.showImportOrders = await unleashFeatureEnabled(UnleashKeys.ImportOrders);
      this.showEditRaffle = await unleashFeatureEnabled(UnleashKeys.EditRaffle);
      this.showTopSellersReport = await unleashFeatureEnabled(UnleashKeys.TopSellersReport);
      this.canCreateCashSales = await unleashFeatureEnabled(UnleashKeys.CreateCashSales);
      this.canDeactivateRaffle = await unleashFeatureEnabled(UnleashKeys.DeactivateRaffle);
      this.canActivateRaffle = await unleashFeatureEnabled(UnleashKeys.ActivateRaffle);
      this.canDeleteRaffle = await unleashFeatureEnabled(UnleashKeys.DeleteRaffle);
      this.canEndRaffle = await unleashFeatureEnabled(UnleashKeys.EndRaffle);
      this.canPrintOrder = await unleashFeatureEnabled(UnleashKeys.PrintOrder);
      this.canSendTestOrderEmail = await unleashFeatureEnabled(UnleashKeys.SendTestOrderEmail);
      this.canRequestRaffleActivation = await unleashFeatureEnabled(UnleashKeys.RequestRaffleActivation);
      this.showNewPrizeFlow = await unleashFeatureEnabled(UnleashKeys.PrizeDrawsNewPrizeFlow);
      this.tags = this.event.tags;
      const sessionUser = await getAuth().sessionUser();
      const organization = await OrganizationServiceV2.retrieveOrganization(sessionUser.organizationUuid);

      // This isn't really a great solution. Should be contained in the enableFeature.
      if (sessionUser.hasRole(Roles.RB_ADMIN)) {
        this.settings.canActivateEvents = true;
        this.isRbAdmin = true;
      } else {
        this.settings.canActivateEvents = organization.settings.canActivateEvents;
      }

      if (this.event.drawType === 'barrel') {
        this.drawType = 'Print Tickets';
      } else if (this.event.drawType === 'rng') {
        this.drawType = 'Random Number Generator';
      }

      // 993px is the lg size of the responsive grid
      if (this.windowWidth < 993) {
        this.openDrawer = false;
      }
    } catch (error) {
      this.onError(error);
    } finally {
      this.loadingEvent = false;
    }
  },
  beforeDestroy() {
    this.$store.dispatch('setEvent', {});
  }
};
</script>
<style scoped>
body {
  background-color: #fdfbfb;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-width: 2px;
}

.container-search {
  padding-bottom: 1rem;
}

.raffle-sidebar {
  flex: 0 0 20rem;
  margin: 0 auto 8rem;
}

.raffle-name {
  font-size: 2.5rem;
  line-height: 1.2;
}

.raffle-name + button {
  margin-left: 1em;
}

.title-max-width {
  max-width: 50%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.title-max-width + button {
  margin-top: 5px;
  margin-bottom: auto;
}

.title-max-width:hover {
  max-width: 70%;
  white-space: inherit;
}

.card-title {
  font-size: 2rem;
}

.btn-pick-winner {
  min-width: 12rem;
  margin-right: 0.5rem;
}

.winner-information {
  vertical-align: top;
}

.blurb a {
  overflow-wrap: anywhere;
}

.tab-container {
  display: flex;
  flex: 1 0 calc(100% - 24rem);
  flex-direction: column;
  max-width: calc(100% - 24rem);
}

@media screen and (min-width: 62em) {
  .tab-container {
    margin-left: 1rem;
  }
}

@media screen and (min-width: 60em) and (max-width: 75em) {
  .raffle-sidebar {
    flex: 0 0 16rem;
  }

  .tab-container {
    flex: 1 0 calc(100% - 20rem);
    max-width: calc(100% - 20rem);
  }
}

@media screen and (max-width: 60em) {
  .tab-container {
    flex: auto;
    max-width: 100%;
  }
  .raffle-sidebar {
    flex: 0 0 23rem;
  }
}

.card-title {
  max-width: 1440px;
}
.raffle-sidebar .card-img {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.tab-border,
.tab-border:first-child {
  max-width: 100em;
}
.missing-image {
  background-color: lightgray;
  border: 1px solid black;
  text-align: center;
  height: 10rem;
  margin-bottom: 1rem;
}
.list-decimal {
  list-style: decimal;
  padding-left: 2rem;
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
}
.nav-link {
  display: flex;
  align-items: center;
  justify-content: center;
}

.qr-download-wrapper {
  width: 150px; /* size of the QR code */
  height: 150px; /* size of the QR code */
}
</style>

// Leave this unscoped to override the default bootstrap styles
<style>
.nav-tabs .nav-link {
  color: #212529;
  height: 100%;
}
.nav-tabs .nav-link.active {
  color: #214b24;
}
</style>
